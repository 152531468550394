import Notification from "../components/Notification/Notification";
import {notification} from "antd";

export const DEFAULT_ERROR = "Server Error!"

export const MESSAGE = {
    ORDO_DELETED: "The ordo has been successfully deleted.",
    CELEBRATION_CREATED: "A new celebration has been successfully created.",
    CELEBRATION_UPDATED: "The celebration has been successfully updated.",
    CELEBRATION_DELETED: "The celebration has been successfully deleted.",
    EVENT_CREATED: "A new event has been successfully created.",
    EVENT_UPDATED: "The event has been successfully updated.",
    EVENT_DELETED: "The event has been successfully deleted.",
    OVERRIDE_EXECUTED: "The calendar changes have been successfully applied.",
    OVERRIDE_DELETED: "The override events have been successfully deleted.",
    OVERRIDE_SAVED: "The override events have been successfully updated.",
    OVERRIDE_CREATED: "The override events have been successfully created.",
};

export const showSuccess = (message) => {
    Notification({type: "success", message});
};

export const showError = (message, description= '') => {
    notification.error({type: "error", message, description});
};
