import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API;
let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
    failedQueue.forEach((promise) => {
        if (token) {
            promise.resolve(token);
        } else {
            promise.reject(error);
        }
    });

    failedQueue = [];
};

const setupAxiosInterceptors = (onUnauthenticated) => {
    axios.interceptors.request.use((config) => {
        const token = localStorage.getItem("idToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, (error) => Promise.reject(error));

    axios.interceptors.response.use((response) => response, async (error) => {
        const status = error.response ? error.response.status : 0;
        const originalRequest = error.config;

        if (status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({resolve, reject});
                })
                    .then((token) => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        return axios(originalRequest);
                    })
                    .catch((err) => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const refresh_token = localStorage.getItem("refreshToken");
                if (!refresh_token) {
                    throw new Error("No refresh token available");
                }

                const {data} = await axios.post("v2/auth/refresh", {refresh_token});

                localStorage.setItem("idToken", data.id_token);
                processQueue(null, data.id_token);
                originalRequest.headers.Authorization = `Bearer ${data.id_token}`;
                return axios(originalRequest);
            } catch (err) {
                processQueue(err, null);
                onUnauthenticated();
                return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    });
}

export default setupAxiosInterceptors;
