import {useQuery} from "@tanstack/react-query";
import {getCalendar} from "../apis/calendarApi";

const convertData = (data) => {
    let result = [];

    for (let key in data) {
        let wholeItem = data[key].find((item) => "ordo" in item);
        if (data[key].length === 1) {
            result.push({
                date: key,
                ...data[key][0],
            });
        } else {
            result.push({
                ordo: wholeItem?.ordo,
                color: wholeItem?.color,
                week_in_psalter: wholeItem?.week_in_psalter,
                date: key,
                children: data[key].slice(1),
                ...data[key][0],
            });
        }
    }

    return result;
};

const useGetCalendar = (ordo, dataRange, pageObj) => {
    return useQuery({
        queryKey: ["calendar", ordo, dataRange.start_date, dataRange.end_date, pageObj.page, pageObj.limit],
        queryFn: () => getCalendar(ordo, dataRange.start_date, dataRange.end_date, pageObj.page, pageObj.limit),
        select: (data) => ({
            data: convertData(data?.events || {}),
            total: data?.total ?? 0,
        }),
    });
};

export default useGetCalendar;
