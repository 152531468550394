import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import {Layout} from "antd";
import ChangePasswordModal from "../Modal/ChangePasswordModal";
import {AuthProvider} from "../../context/AuthProvider";
import AppHeader from "./Header";

const {Footer, Content} = Layout;

const contentStyle = {
    margin: "24px 16px 0", backgroundColor: "#ffffff", minHeight: "calc(100vh - 152px)",
};

const footerStyle = {
    textAlign: "center", maxHeight: "64px", background: "#0a3b24", color: "white",
};

const MainLayout = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (<AuthProvider>
        <Layout>
            <AppHeader/>

            <Content style={contentStyle}>
                <Outlet/>
            </Content>

            <Footer style={footerStyle}>
                Liturgical Calendar ©{new Date().getFullYear()} Created by Dane
            </Footer>
            <ChangePasswordModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
        </Layout>
    </AuthProvider>);
};

export default MainLayout;
