import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {getEvents} from "../apis/eventApi";
import {useEffect, useMemo, useRef} from "react";
import {DEFAULT_ERROR, showError} from "../constants/notification";
import {deepCleanEntity} from "../utils/form";

const convertData = (data) => {
    let result = [];

    for (let key in data) {
        let wholeItem = data[key].find((item) => "ordo" in item);
        if (data[key].length === 1) {
            result.push({
                day: key,
                ...data[key][0],
            });
        } else {
            result.push({
                ordo: wholeItem?.ordo,
                color: wholeItem?.color,
                week_in_psalter: wholeItem?.week_in_psalter,
                day: key,
                children: data[key].slice(1),
                ...data[key][0],
            });
        }
    }

    return result;
};

export const useGetEvents = (params) => {
    const memoizedParams = useMemo(() => params, [JSON.stringify(params)]);

    return useQuery({
        queryKey: ["events", memoizedParams],
        queryFn: () => getEvents(params),
        select: (data) => ({
            events: convertData(data?.events || {}),
            total: data?.total ?? 0,
        }),
        enabled: !!params?.ordo,
        onError: (error) => {
            showError(error?.response?.data?.detail || DEFAULT_ERROR)
        },
    });
};


const useInfiniteEvent = (params) => {
    const memoizedParams = useMemo(() => params, [JSON.stringify(params)]);
    const containerRef = useRef(null);
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
    } = useInfiniteQuery({
        enabled: !!params?.ordo,
        queryKey: ['items', memoizedParams],
        queryFn: ({pageParam = 0}) => getEvents({page: pageParam, ...deepCleanEntity(params)}),
        getNextPageParam: (lastPage) => (lastPage.hasNext ? lastPage.nextPage : undefined),
    });

    const groups = data?.pages
        ? data.pages
            .flatMap((page) => {
                return Object.entries(page.events).flatMap(([date, events]) => {
                    return events.map(event => ({
                        ...event,
                        value: event.id,
                        label: `${date} ${event.title}`,
                        date: date,
                    }));
                });
            })
        : [];

    const handleScroll = () => {
        if (
            containerRef.current &&
            containerRef.current.scrollHeight - containerRef.current.scrollTop === containerRef.current.clientHeight
        ) {
            if (hasNextPage && !isFetchingNextPage) {
                fetchNextPage();
            }
        }
    };

    useEffect(() => {
        const ref = containerRef.current;
        if (ref) {
            ref.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (ref) {
                ref.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasNextPage, isFetchingNextPage]);

    return {
        groups,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
        containerRef,
    };
};

export default useInfiniteEvent;
