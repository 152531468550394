import axios from "axios";

export const getCelebrations = async (
    params
) => {
    const res = await axios.get(
        `/v2/celebrations/ordos/${params?.ordo}`, {
            params
        }
    );
    return res.data;
};

export const createCelebration = async (data) => {
    return await axios.post(
        '/v2/celebrations',
        data
    )
};

export const updateCelebration = async (id, data) => {
    return await axios.put(
        `/v2/celebrations/${id}`,
        data
    );
};

export const deleteCelebration = async (id) => {
    return await axios.delete(
        `/v2/celebrations/${id}`
    );

};
