import axios from "axios";
import {handleValidationError} from "../utils/errorHandler";

export const getSeasons = async () => {
  try {
    const res = await axios.get('/v2/seasons');

    return res.data;
  } catch (error) {

    handleValidationError(error)
    throw error
  }
};
