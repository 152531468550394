import axios from "axios";

export const getCalendar = async (ordo, start_date, end_date, page, limit) => {
    const res = await axios.get(`/v2/calendar/${ordo}`, {
        params: {
            ...(start_date && {start_date}), ...(end_date && {end_date}), page, limit,
        }
    });

    return res.data;
};
