import React, {useCallback, useEffect, useMemo, useState} from "react";
import {DatePicker, Form, Input, InputNumber, Modal, Radio, Select, Tag} from "antd";
import dayjs from "dayjs";
import useInfiniteEvent from "../../../hooks/useEvent";
import {debounce} from 'lodash'
import {useWatch} from "antd/lib/form/Form";
import {WEEKDAYS} from "../../../constants";
import {useCreateCalendarEvent, useUpdateCalendarEvent} from "../../../hooks/useOverrides";

const CalendarModal = ({
                           canCreate = true,
                           canDelete = true,
                           isNew,
                           editData,
                           isModalOpen,
                           setIsModalOpen,
                           ordoOptions,
                           rankOptions,
                           seasonOptions,
                           callback
                       }) => {
    const [form] = Form.useForm();

    const [search, setSearch] = useState("");
    const ordo = useWatch('ordo', form);
    const [defaultValue, setDefaultValue] = useState(null);
    const handleRefetch  = () => {
        form.resetFields();
        setIsModalOpen(false);
        callback?.()
    }
    const {handleUpdateEvent, isPending: isPendingUpdate} = useUpdateCalendarEvent(handleRefetch)
    const {handleCreateEvent, isPending: isPendingCreate} = useCreateCalendarEvent(handleRefetch)
    useEffect(() => {
        if (isModalOpen) {
            form.setFieldsValue({
                ...editData, action: editData?.action || (isNew && canCreate ? 'create' : 'update')
            });
            form.setFieldValue("day", dayjs(editData.day));
            setDefaultValue(editData.calendar_id ? {
                ...editData, value: editData.calendar_id, label: editData.title,
            } : null)
        }
    }, [isNew, editData, isModalOpen, form]);

    const {
        groups, isLoading, containerRef,
    } = useInfiniteEvent({search, ordo});

    const mergedOptions = defaultValue
        ? [...groups, defaultValue].filter(
            (item, index, self) => self.findIndex((o) => o.value === item.value) === index
        )
        : groups;

    const formItemLayout = {
        labelCol: {
            xs: {span: 24}, sm: {span: 6},
        }, wrapperCol: {
            xs: {span: 24}, sm: {span: 14},
        },
    };
    const action = useWatch('action', form);
    const showEvent = useMemo(() => {
        return action !== 'create'
    }, [action])

    const handleSearch = useCallback(debounce((value) => {
        setSearch(value);
    }, 500), []);

    const onOk = async () => {
        try {
            const {day: eventDate, ...rest} = await form.validateFields();

            const eventData = {
                ...rest, year: eventDate.year(), day: eventDate.format("YYYY-MM-DD"), dow: WEEKDAYS[eventDate.day()],
            };
            if (isNew) {
                await handleCreateEvent(eventData)
            } else {
                await handleUpdateEvent(editData.id, eventData);
            }

        } catch (error) {
            console.log("Validate Failed:", error);
        }
    };


    const onCancel = async () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const handleChangeOrdo = (value) => {
        setDefaultValue(null)
        form.setFieldsValue({'ordo': value, 'calendar_id': undefined})
        form.resetFields(["calendar_id", "day", "title", "precedence_id", "color", "rank_name", "season_name", "commons", "slug",]);
    }

    const handleChangeEvent = (value, option) => {
        if (!value) {
            form.resetFields(["day", "title", "precedence_id", "color", "rank_name", "season_name", "commons", "slug",]);
        } else {
            form.setFieldsValue({
                day: dayjs(option.date),
                title: option.title,
                precedence_id: option.precedence_id,
                color: option.color,
                // location: option.ordo,
                rank_name: option.rank_name,
                season_name: option.season_name,
                commons: option.commons,
                slug: option.slug,
            });
        }
    }

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    return (<Form name="calendar_form" {...formItemLayout} variant="filled" form={form}>
        <Modal
            title={<p className={'text-center mb-4'}>
                {isNew && canCreate ? "New Liturgical Event Override" : "Edit Liturgical Event Override"}
            </p>}
            open={isModalOpen}
            okText="Save"
            onOk={onOk}
            onCancel={onCancel}
            okButtonProps={{loading: isPendingUpdate || isPendingCreate}}
            width={600}
        >
            <Form.Item
                label="Action"
                name="action"
                initialValue={isNew && canCreate ? "create" : "update"}
                rules={[{required: true, message: "Please choose an action!"}]}
                style={{marginBottom: "8px"}}
            >
                <Radio.Group onChange={(e) => form.setFieldValue("action", e.target.value)}>
                    {canCreate && <Radio value="create">
                        <Tag color="#108ee9" key={'create'}>
                            {'Create'.toUpperCase()}
                        </Tag>
                    </Radio>}
                    <Radio value="update"><Tag color="#87d068" key={'update'}>
                        {'Update'.toUpperCase()}
                    </Tag>
                    </Radio>
                    {canDelete && <Radio value="delete">
                        <Tag color="#f00" key={'delete'}>
                            {'Delete'.toUpperCase()}
                        </Tag>

                    </Radio>}
                </Radio.Group>

            </Form.Item>
            <Form.Item
                label="Ordo"
                name="ordo"
                initialValue={"gen"}
                rules={[{required: true, message: "Please select the ordo!"}]}
                style={{marginBottom: "8px"}}
            >
                <Select
                    showSearch
                    onChange={handleChangeOrdo}
                    placeholder="Select a Ordo"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    options={ordoOptions}
                    style={{width: "160px", marginRight: "24px"}}
                />
            </Form.Item>
            {showEvent ? <Form.Item
                shouldUpdate={true}
                label="Event"
                name="calendar_id"
                dependencies={["action"]}
                rules={[() => ({
                    required: true, message: "Please select an event when updating!",
                }),]}
                style={{marginBottom: "8px"}}
            >
                <Select
                    showSearch
                    allowClear={true}
                    filterOption={false}
                    loading={isLoading}
                    onSearch={handleSearch}
                    onChange={handleChangeEvent}
                    dropdownRender={(menu) => {
                        return (<div ref={containerRef}>
                            {menu}
                        </div>)
                    }}

                    options={mergedOptions}
                />
            </Form.Item> : null}

            <Form.Item
                label="Date"
                name="day"
                rules={[{required: true, message: "Please input Date!"}]}
                style={{marginBottom: "8px"}}
            >
                <DatePicker format={"YYYY-MM-DD"}/>
            </Form.Item>
            <Form.Item
                label="Title"
                name="title"
                rules={[{required: true, message: "Please input title!"}]}
                style={{marginBottom: "8px"}}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Precedence ID"
                name="precedence_id"
                rules={[{required: true, message: "Please input the precedence_id!"},]}
                style={{marginBottom: "8px"}}
            >
                <InputNumber min={1} max={13}/>
            </Form.Item>
            <Form.Item
                label="Color"
                name="color"
                rules={[{required: true, message: "Please input color!"}]}
                style={{marginBottom: "8px"}}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Rank"
                name="rank_name"
                initialValue={"Solemnity"}
                rules={[{required: true, message: "Please select the rank!"}]}
                style={{marginBottom: "8px"}}
            >
                <Select
                    showSearch
                    placeholder="Select a Rank"
                    optionFilterProp="children"
                    defaultValue={"Solemnity"}
                    filterOption={filterOption}
                    options={rankOptions}
                    style={{width: "160px", marginRight: "24px"}}
                />
            </Form.Item>
            <Form.Item
                label="Season"
                name="season_name"
                initialValue={"Advent"}
                rules={[{required: true, message: "Please select the season!"}]}
                style={{marginBottom: "8px"}}
            >
                <Select
                    showSearch
                    placeholder="Select a Season"
                    optionFilterProp="children"
                    defaultValue={"Advent"}
                    filterOption={filterOption}
                    options={seasonOptions}
                    style={{width: "160px", marginRight: "24px"}}
                />
            </Form.Item>
            <Form.Item
                label="Commons"
                name="commons"
                style={{marginBottom: "8px"}}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Slug"
                name="slug"
                rules={[{required: true, message: "Please input slug!"}]}
                style={{marginBottom: "8px"}}
            >
                <Input/>
            </Form.Item>
        </Modal>
    </Form>);
};

export default CalendarModal;
