import React, { useEffect, useState } from "react";
import { Spin, Button, Input, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { getOrdosByPagination, deleteOrdo } from "../../../apis/ordoApi";
import DashboardTable from "../../../components/Table/DashboardTable";
import OrdoModal from "./OrdoModal";
import {useAuth} from "../../../context/AuthProvider";

const { Search } = Input;

const Ordos = () => {
  const {isAdministrator} = useAuth()
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [pageObj, setPageObj] = useState({ page: 1, limit: "15" });
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [editData, setEditData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const getData = async () => {
      const response = await getOrdosByPagination(
        search,
        pageObj.page - 1,
        pageObj.limit,
        setLoading
      );

      if (response) {
        setTotal(response.total);
        setData(response.ordos);
      }
    };

    getData();
  }, [search, pageObj, currentDate]);

  const columns = [
    {
      title: "No",
      key: "Number",
      dataIndex: "index",
      width: "3%",
      align: "center",
    },
    {
      title: "Ordo",
      key: "id",
      dataIndex: "id",
      width: "5%",
      align: "center",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      align: "center",
    },
    {
      title: "Locale",
      key: "locale",
      dataIndex: "locale",
      width: "10%",
      align: "center",
    },
    {
      title: "Epiphany Placement",
      key: "epiphany_placement",
      dataIndex: "epiphany_placement",
      width: "10%",
      align: "center",
    },
    {
      title: "Actions",
      key: "action",
      width: "5%",
      align: "center",
      render: (_, record) => (
        <center>
          <Button
            type="primary"
            icon={<EditOutlined style={{ width: "12px", height: "12px" }} />}
            style={{ marginRight: "4px", width: "20px", height: "20px" }}
            onClick={() => onEdit(record)}
          />
          <Popconfirm
            title="Delete Ordo"
            description="Are you sure to delete this ordo?"
            onConfirm={() => onDelete(record.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              danger
              icon={
                <DeleteOutlined style={{ width: "12px", height: "12px" }} />
              }
              style={{ marginRight: "4px", width: "20px", height: "20px" }}
            />
          </Popconfirm>
        </center>
      ),
    },
  ];

  const onNew = () => {
    setIsNew(true);
    setIsModalOpen(true);
  };

  const onEdit = (record) => {
    setIsNew(false);
    setEditData(record);
    setIsModalOpen(true);
  };

  const onDelete = (id) => {
    deleteOrdo(id);
    setCurrentDate(new Date());
  };

  const handleChange = async (page, pageSize) => {
    setPageObj((oldState) => ({
      ...oldState,
      page: page,
      limit: pageSize,
    }));
  };

  const handleSearch = async (value) => {
    setSearch(value);
    setPageObj((oldState) => ({
      ...oldState,
      page: 1,
    }));
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <Search placeholder="Search" onSearch={handleSearch} enterButton />
          </div>
          {isAdministrator ? (
            <div>
              <Button type="primary" icon={<PlusOutlined />} onClick={onNew}>
                Add New Ordo
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <Spin spinning={loading}>
          <DashboardTable
            bordered
            columns={isAdministrator ? columns : columns.slice(0, -1)}
            dataSource={data.map((row, index) => ({
              ...row,
              index: index + 1 + (pageObj.page - 1) * pageObj.limit,
            }))}
            scroll={{
              x: 1200,
            }}
            pagination={{
              position: ["bottomRight"],
              pageSize: pageObj.limit,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: total,
              current: pageObj.page,
              showSizeChanger: true,
              onShowSizeChange: (current, size) => {
                setPageObj({ page: current, limit: size });
              },
              onChange: (page, pageSize) => {
                handleChange(page, pageSize);
              },
            }}
            rowKey={(record) => record.id}
          />
        </Spin>
      </div>
      <OrdoModal
        isNew={isNew}
        editData={editData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setCurrentDate={setCurrentDate}
      />
    </div>
  );
};

export default Ordos;
