import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, Dropdown, Layout} from "antd";
import {LockOutlined, PoweroffOutlined, UserOutlined} from "@ant-design/icons";
import {useAuth} from "../../context/AuthProvider";
import ChangePasswordModal from "../Modal/ChangePasswordModal";

const headerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
    background: "#0a3b24",
    maxHeight: "64px",
};

const avatarStyle = {
    background: "#308f59",
    marginRight: "12px",
};

const AppHeader = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {isAdministrator, userInfo} = useAuth();

    const changePassword = () => setIsModalOpen(true);

    const logout = () => {
        localStorage.removeItem("idToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/");
    };

    const menuItems = [
        {key: "0", label: "Change Password", icon: <LockOutlined/>, onClick: changePassword},
        {key: "1", label: "Log Out", icon: <PoweroffOutlined/>, onClick: logout},
    ];

    return (
        <Layout.Header style={headerStyle}>
            {userInfo ? (
                <Dropdown menu={{items: menuItems}} trigger={["click"]}>
                    <div className="d-flex align-items-center gap-2 text-white">
                        <div>{isAdministrator ? "Administrator" : "User"}</div>
                        <div>{userInfo?.email}</div>
                        <Avatar style={avatarStyle} shape="square" size={36} icon={<UserOutlined/>}/>
                    </div>
                </Dropdown>
            ) : null}

            <ChangePasswordModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        </Layout.Header>
    );
};

export default AppHeader;
