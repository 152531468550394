import axios from "axios";

export const getOverridesByPagination = async (
    search,
    page,
    limit,
) => {
    const res = await axios({
        method: 'get',
        url: '/v2/celebrations_override',
        params: {
            search,
            page,
            limit
        }
    });

    return res?.data;
};

export const executeOverrides = async (ids) => {
    return await axios.post(
        '/v2/celebrations_override/execute',
        ids
    );
};

export const deleteOverrides = async (ids) => {
    return await axios.post(
        '/v2/celebrations_override/batch-delete',
        ids
    );
}

export const createCalendarEvent = async (data) => {
    return await axios.post('/v2/celebrations_override', data);
};

export const updateCalendarEvent = async ({id, data}) => {
    return await axios.put(`/v2/celebrations_override/${id}`, data);
};
