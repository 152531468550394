import axios from "axios";
import Notification from "../components/Notification/Notification";
import {DEFAULT_ERROR, showError} from "../constants/notification";
import {handleValidationError} from "../utils/errorHandler";

export const getEvents = async ({ordo, ...params}) => {
  const { data } = await axios.get(`/v2/events/${ordo}`, { params });
  return data;
};

export const createEvent = async (data) => {
  try {

    const res = await axios.post(
      `/v2/events`,
      data
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "New Event is created!" });
    }
  } catch (error) {
    showError(error?.response?.data?.detail || DEFAULT_ERROR)
    return error
  }
};

export const updateEvent = async (id, data) => {
  try {

    const res = await axios.put(
      `/v2/events/${id}`,
      data
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "Event is updated!" });
    }
  } catch (error) {
    handleValidationError(error)
    return error
  }
};

export const deleteEvent = async (id) => {
  try {
    const res = await axios.delete(
      `/v2/events/${id}`
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "Event is deleted!" });
    }
  } catch (error) {
    handleValidationError(error)
    throw error
  }
};
