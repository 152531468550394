import {DEFAULT_ERROR, showError} from "../constants/notification";
import {HttpStatusCode} from "axios";

export const handleValidationError = (error) => {
    if (error.response?.status === HttpStatusCode.UnprocessableEntity) {
        const validationErrors = (
            <div className={'d-flex flex-column'}>
                {error.response.data.detail.map((err, index) => (
                    <span key={index}>
                        {err?.loc?.[1]}: {err?.msg}
                    </span>
                ))}
            </div>
        );
        showError("Validation Error", validationErrors);
    } else {
        showError(error?.response?.data?.detail || DEFAULT_ERROR);
    }
};
