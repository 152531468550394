import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {PoweroffOutlined} from "@ant-design/icons";
import {FloatButton, Modal} from "antd";
import Calendar from "./calendar/Calendar";
import Events from "./events/Events";
import MainTabs from "../../components/Tab/MainTabs";
import Celebrations from "./celebrations/Celebrations";
import Ordos from "./ordos/Ordos";
import Overrides from "./overides/Overrides";

const Dashboard = () => {
  const navigate = useNavigate();
  const [logOutModal, setLogOutModal] = useState(false);
  const {currentTab} = useParams()

  const items = [
    {
      key: "calendar",
      label: "Calendar",
      children: <Calendar />,
    },
    {
      key: "events",
      label: "Liturgical Events",
      children: <Events />,
    },
    {
      key: "celebrations",
      label: "Celebrations",
      children: <Celebrations />,
    },
    // {
    //   key: "commons",
    //   label: "Commons",
    //   children: <Commons />,
    // },
    {
      key: "ordors",
      label: "Ordos",
      children: <Ordos />,
    },
    {
      key: "overrides",
      label: "Overrides",
      children: <Overrides />,
    },
  ];

  const handleChange = (key) => {
    navigate(`/dashboard/${key}`)
  };

  const handleOk = async () => {
    setLogOutModal(false);
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/");
  };

  const handleCancel = async () => {
    setLogOutModal(false);
  };

  return (
    <>
      <MainTabs
        type="card"
        defaultActiveKey="calendar"
        items={items}
        activeKey={currentTab}
        onChange={handleChange}
        destroyInactiveTabPane={true}
      />
      <FloatButton
        icon={<PoweroffOutlined />}
        type="primary"
        style={{
          right: 24,
        }}
        onClick={() => setLogOutModal(true)}
      />
      <Modal
        open={logOutModal}
        title="Logout"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p>Do you want to Log out?</p>
      </Modal>
    </>
  );
};

export default Dashboard;
