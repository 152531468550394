import {ConfigProvider} from "antd";
import AppRoutes from "./AppRoutes";
import "./App.css";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

const queryClient = new QueryClient()

const App = () => {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: "#308f59",
                            colorPrimaryHover: "#46b978",
                        },
                    }}
                >
                    <AppRoutes/>
                </ConfigProvider>
                {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}

            </QueryClientProvider>
        </div>
    );
};

export default App;
