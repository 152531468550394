import React, {useCallback, useState} from "react";
import {Button, DatePicker, Input, Select, Spin} from "antd";
import DashboardTable from "../../../components/Table/DashboardTable";
import dayjs from "dayjs";
import useFetchOrdos from "../../../hooks/useFetchOrdos";
import {useGetEvents} from "../../../hooks/useEvent";
import {DoubleRightOutlined} from "@ant-design/icons";
import useManageEvent from "../../../hooks/useManageEvent";
import CalendarModal from "../calendar/CalendarModal";
import useFetchSeasons from "../../../hooks/useFetchSeasons";
import useFetchRanks from "../../../hooks/useFetchRank";
import {useNavigate} from "react-router-dom";

const {RangePicker} = DatePicker;

const {Search} = Input;


const Events = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState("");
    const [ordo, setOrdo] = useState("gen");
    const [ordoDescription, setOrdoDescription] = useState("General Roman Calendar for the Roman Catholic Church");
    const [dateRange, setDateRange] = useState({
        start_date: dayjs().startOf("year").format("YYYY-MM-DD"), end_date: dayjs().endOf("year").format("YYYY-MM-DD"),
    })
    const [pageObj, setPageObj] = useState({page: 1, limit: "15"});
    const {data, isLoading, refetch} = useGetEvents({
        search,
        ordo,
        start_date: dateRange.start_date,
        end_date: dateRange.end_date,
        page: pageObj.page - 1,
        limit: pageObj.limit
    });
    const events = data?.events ?? [];
    const total = data?.total ?? 0;


    const {ordoOptions} = useFetchOrdos();

    const columns = [{
        title: "Date", key: "day", dataIndex: "day", width: "7%", align: "center",
    }, {
        title: "Ordo", key: "ordo", dataIndex: "ordo", width: "2%", align: "center",
    }, {
        title: "Title", key: "title", dataIndex: "title", align: "center",
    }, {
        title: "Precedence", key: "precedence_id", dataIndex: "precedence_id", width: "6%", align: "center",
    }, {
        title: "Color", key: "color", dataIndex: "color", width: "4%", align: "center",
    }, {
        title: "Rank", key: "rank_name", dataIndex: "rank_name", width: "7%", align: "center",
    }, {
        title: "Season", key: "season", dataIndex: "season_name", width: "7%", align: "center",
    }, {
        title: "Week in Season", key: "week_in_season", dataIndex: "week_in_season", width: "7%", align: "center",
    }, {
        title: "Week in Psalter", key: "week_in_psalter", dataIndex: "week_in_psalter", width: "7%", align: "center",
    }, {
        title: "Day of Week", key: "dow", dataIndex: "dow", width: "6%", align: "center",
    }, {
        title: "Commons", key: "commons", dataIndex: "commons", width: "5%", align: "center",
    }, {
        title: "Lable/Slug", key: "slug", dataIndex: "slug", width: "9%", align: "center",
    }, {
        title: "Dominical", key: "dominical_letter", dataIndex: "dominical_letter", width: "5%", align: "center",
    }, {
        title: "Year Cycle", key: "year_cycle_letter", dataIndex: "year_cycle_letter", width: "5%", align: "center",
    }, {
        title: "", key: "action", width: "5%", align: "center", render: (_, record) => (<center>
            <Button
                title={'Create an override'}
                type="primary"
                icon={<DoubleRightOutlined/>}
                style={{marginRight: "4px", width: "20px", height: "20px"}}
                onClick={() => handleCreateOverride(record)}
            />
        </center>),
    },];
    const {seasonOptions} = useFetchSeasons()
    const {rankOptions} = useFetchRanks()
    const {
        isNew, editData, isModalOpen, setIsModalOpen, setIsNew, setEditData,
    } = useManageEvent()
    const handleCreateOverride = (record) => {
        setIsNew(true);
        setEditData({...record, calendar_id: record?.id});
        setIsModalOpen(true);
    }

    const handleDateChange = useCallback((dates, dateStrings) => {
        setDateRange({start_date: dateStrings[0], end_date: dateStrings[1]});
        setPageObj((prev) => ({...prev, page: 1}));
        refetch();
    }, [refetch]);

    const handleOrdoChange = useCallback((value, option) => {
        setOrdo(value);
        setOrdoDescription(option.description);
        setPageObj((prev) => ({...prev, page: 1}));
        refetch();
    }, [refetch]);

    const handleChange = useCallback(async (page, pageSize) => {
        setPageObj((oldState) => ({
            ...oldState, page: page, limit: pageSize,
        }));
        refetch()
    }, [refetch]);

    const handleSearch = async (value) => {
        setSearch(value);
        setPageObj((oldState) => ({
            ...oldState, page: 1,
        }));
    };
    const gotoOverride = () => {
        navigate('/dashboard/overrides')
    }

    return (<div className="p-4">
        <div className="mb-4">
            <div className="d-flex justify-content-between mb-2">
                <div className="d-flex align-items-center">
                    <Search placeholder="Search" onSearch={handleSearch} enterButton/>
                </div>
            </div>
            <div className="mb-4">
                <div className="d-flex mb-2 align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <span style={{width: "48px"}}>Ordo:</span>
                        <Select
                            showSearch
                            placeholder="Select an Ordo"
                            optionFilterProp="children"
                            defaultValue="gen"
                            onChange={handleOrdoChange}
                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                            options={ordoOptions}
                            style={{width: "100px", marginRight: "24px"}}
                        />
                        <span>{ordoDescription}</span>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <span style={{width: "48px"}}>Date:</span>
                    <RangePicker
                        defaultValue={[dayjs(dateRange.start_date), dayjs(dateRange.end_date)]}
                        onChange={handleDateChange}
                    />
                </div>
            </div>
        </div>

        <div>
            <Spin spinning={isLoading}>
                <DashboardTable
                    bordered
                    columns={columns}
                    dataSource={events}
                    scroll={{
                        x: 1200,
                    }}
                    pagination={{
                        position: ["bottomRight"],
                        pageSize: pageObj.limit,
                        pageSizeOptions: ["15", "25", "50", "100"],
                        total: total,
                        current: pageObj.page,
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            setPageObj({page: current, limit: size});
                        },
                        onChange: (page, pageSize) => {
                            handleChange(page, pageSize);
                        },
                    }}
                    rowKey={(record) => record.day}
                />
            </Spin>
        </div>
        {isModalOpen && <CalendarModal
            canCreate={false}
            isNew={isNew}
            editData={editData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            ordoOptions={ordoOptions}
            rankOptions={rankOptions}
            seasonOptions={seasonOptions}
            callback={gotoOverride}
        />}

    </div>);
};

export default Events;
