import axios from "axios";
import {handleValidationError} from "../utils/errorHandler";

export const getRanks = async () => {
  try {
    const res = await axios.get('/v2/ranks');

    return res.data;
  } catch (error) {
    handleValidationError(error)
    throw error
  }

};
