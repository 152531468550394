import { jwtDecode } from "jwt-decode";
import { refresh } from "../apis/authApi";

const verifyToken = async () => {
  const token = localStorage.getItem("idToken");
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds

    if (decoded.exp < currentTime) {
      console.log("Token is expired, refreshing token...");
      const response = await refresh();

      if(response?.id_token) {
        localStorage.setItem("idToken", response?.id_token);
      }
      localStorage.setItem("accessToken", response.access_token);
      console.log("Token is refreshed!");
    } else {
      console.log("Token is valid!");
    }

    return true;
  } catch (error) {
    console.error("Error decoding token!", error);
    return false;
  }
};

export default verifyToken;
