import React, {useCallback, useState} from "react";
import {DatePicker, Select, Spin} from "antd";
import dayjs from "dayjs";
import DashboardTable from "../../../components/Table/DashboardTable";
import useFetchOrdos from "../../../hooks/useFetchOrdos";
import useGetCalendar from "../../../hooks/useGetCalendar";

const { RangePicker } = DatePicker;

const columns = [
    {
        title: "Date",
        key: "date",
        dataIndex: "date",
        width: "7%",
        align: "center",
    },
    {
        title: "Ordo",
        key: "ordo",
        dataIndex: "ordo",
        width: "2%",
        align: "center",
    },
    {
        title: "Title",
        key: "title",
        dataIndex: "title",
        align: "center",
    },
    {
        title: "Precedence",
        key: "precedence",
        dataIndex: "precedence",
        width: "6%",
        align: "center",
    },
    {
        title: "Color",
        key: "color",
        dataIndex: "color",
        width: "4%",
        align: "center",
    },
    {
        title: "Rank",
        key: "rank",
        dataIndex: "rank",
        width: "7%",
        align: "center",
    },
    {
        title: "Season",
        key: "season",
        dataIndex: "season",
        width: "7%",
        align: "center",
    },
    {
        title: "Week in Season",
        key: "week_in_season",
        dataIndex: "week_in_season",
        width: "7%",
        align: "center",
    },
    {
        title: "Week in Psalter",
        key: "week_in_psalter",
        dataIndex: "week_in_psalter",
        width: "7%",
        align: "center",
    },
    {
        title: "Day of Week",
        key: "dow",
        dataIndex: "dow",
        width: "6%",
        align: "center",
    },
    {
        title: "Commons",
        key: "commons",
        dataIndex: "commons",
        width: "5%",
        align: "center",
    },
    {
        title: "Lable/Slug",
        key: "label",
        dataIndex: "label",
        width: "9%",
        align: "center",
    },
    {
        title: "Dominical",
        key: "dominical_letter",
        dataIndex: "dominical_letter",
        width: "5%",
        align: "center",
    },
    {
        title: "Year Cycle",
        key: "year_cycle_letter",
        dataIndex: "year_cycle_letter",
        width: "5%",
        align: "center",
    },
];

const Calendar = () => {
    const { ordoOptions } = useFetchOrdos();

    const [ordo, setOrdo] = useState("gen");
    const [ordoDescription, setOrdoDescription] = useState(
        "General Roman Calendar for the Roman Catholic Church"
    );
    const [dateRange, setDateRange] = useState({
        start_date: dayjs().startOf("year").format("YYYY-MM-DD"),
        end_date: dayjs().endOf("year").format("YYYY-MM-DD"),
    });
    const [pageObj, setPageObj] = useState({ page: 1, limit: "15" });

    const { data, isLoading, refetch } = useGetCalendar(ordo, dateRange, pageObj);
    const events = data?.events ?? [];
    const total = data?.real_total ?? 0;

    const handlePageChange = useCallback((page, pageSize) => {
        setPageObj({ page, limit: pageSize });
        refetch();
    }, [refetch]);

    const handleDateChange = useCallback((dates, dateStrings) => {
        setDateRange({ start_date: dateStrings[0], end_date: dateStrings[1] });
        setPageObj((prev) => ({ ...prev, page: 1 }));
        refetch();
    }, [refetch]);

    const handleOrdoChange = useCallback((value, option) => {
        setOrdo(value);
        setOrdoDescription(option.description);
        setPageObj((prev) => ({ ...prev, page: 1 }));
        refetch();
    }, [refetch]);

    return (
        <div className="p-4">
            <div className="mb-4">
                <div className="d-flex mb-2 align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <span style={{ width: "48px" }}>Ordo:</span>
                        <Select
                            showSearch
                            placeholder="Select an Ordo"
                            optionFilterProp="children"
                            defaultValue="gen"
                            onChange={handleOrdoChange}
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            options={ordoOptions}
                            style={{ width: "100px", marginRight: "24px" }}
                        />
                        <span>{ordoDescription}</span>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <span style={{ width: "48px" }}>Date:</span>
                    <RangePicker
                        defaultValue={[dayjs(dateRange.start_date), dayjs(dateRange.end_date)]}
                        onChange={handleDateChange}
                    />
                </div>
            </div>

            <Spin spinning={isLoading}>
                <DashboardTable
                    bordered
                    columns={columns}
                    dataSource={events}
                    scroll={{ x: 1200 }}
                    pagination={{
                        position: ["bottomRight"],
                        pageSize: pageObj.limit,
                        pageSizeOptions: ["15", "25", "50", "100"],
                        total,
                        current: pageObj.page,
                        showSizeChanger: true,
                        onShowSizeChange: handlePageChange,
                        onChange: handlePageChange,
                    }}
                    rowKey={(record) => record.id}
                />
            </Spin>
        </div>
    );
};

export default Calendar;
