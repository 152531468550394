import React, {useMemo, useState} from "react";
import {Button, Input, Popconfirm, Select, Spin} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import DashboardTable from "../../../components/Table/DashboardTable";
import CelebrationModal from "./CelebrationModal";
import {useAuth} from "../../../context/AuthProvider";
import {useCelebrations, useDeleteCelebration} from "../../../hooks/useCelebration";
import useFetchOrdos from "../../../hooks/useFetchOrdos";
import useFetchRanks from "../../../hooks/useFetchRank";

const {Search} = Input;

const Celebrations = () => {
    const {isAdministrator} = useAuth()
    const [search, setSearch] = useState("");
    const [ordo, setOrdo] = useState("all");
    const [ordoDescription, setOrdoDescription] = useState("All Ordos");
    const [pageObj, setPageObj] = useState({page: 1, limit: "15"});
    const [isNew, setIsNew] = useState(false);
    const [editData, setEditData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {handleDeleteCelebration} = useDeleteCelebration()

    const {data, isFetching, refetch} = useCelebrations({
        ordo,
        search,
        page: pageObj.page - 1,
        limit: pageObj.limit,
    })

    const total = useMemo(() => data?.total || 0, [data]);
    const celebrations = useMemo(() => data?.celebrations || [], [data]);
    const {ordoOptions} = useFetchOrdos();
    const {rankOptions} = useFetchRanks()
    const columns = [
        {
            title: "No",
            key: "Number",
            dataIndex: "index",
            width: "3%",
            align: "center",
        },
        {
            title: "Ordo",
            key: "ordo",
            dataIndex: "ordo",
            width: "5%",
            align: "center",
        },
        {
            title: "Title",
            key: "title",
            dataIndex: "title",
            align: "center",
        },
        {
            title: "Precedence",
            key: "precedence_id",
            dataIndex: "precedence_id",
            width: "7%",
            align: "center",
        },
        {
            title: "Color",
            key: "color",
            dataIndex: "color",
            width: "5%",
            align: "center",
        },
        {
            title: "Rank",
            key: "rank_name",
            dataIndex: "rank_name",
            width: "7%",
            align: "center",
        },
        {
            title: "Commons",
            key: "commons",
            dataIndex: "commons",
            width: "7%",
            align: "center",
        },
        {
            title: "Slug",
            key: "slug",
            dataIndex: "slug",
            width: "10%",
            align: "center",
        },
        {
            title: "Placement",
            key: "placement",
            dataIndex: "placement",
            width: "13%",
            align: "center",
        },
        {
            title: "Actions",
            key: "action",
            width: "5%",
            align: "center",
            render: (_, record) => (
                <center>
                    <Button
                        type="primary"
                        icon={<EditOutlined style={{width: "12px", height: "12px"}}/>}
                        style={{marginRight: "4px", width: "20px", height: "20px"}}
                        onClick={() => onEdit(record)}
                    />
                    <Popconfirm
                        title="Delete Celebration"
                        description="Are you sure to delete this celebration?"
                        onConfirm={() => handleDeleteCelebration(record.id)}
                        onCancel={() => {
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            danger
                            icon={
                                <DeleteOutlined style={{width: "12px", height: "12px"}}/>
                            }
                            style={{marginRight: "4px", width: "20px", height: "20px"}}
                        />
                    </Popconfirm>
                </center>
            ),
        },
    ];

    const onNew = () => {
        setIsNew(true);
        setIsModalOpen(true);
    };

    const onEdit = (record) => {
        setIsNew(false);
        setEditData(record);
        setIsModalOpen(true);
    };

    const handleChange = async (page, pageSize) => {
        setPageObj((oldState) => ({
            ...oldState,
            page: page,
            limit: pageSize,
        }));
    };

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleSearch = async (value) => {
        setSearch(value);
        setPageObj((oldState) => ({
            ...oldState,
            page: 1,
        }));
    };

    return (
        <div className="p-4">
            <div className="mb-4">
                <div className="d-flex mb-2 justify-content-between">
                    <div className="d-flex align-items-center">
                        <div style={{width: "48px"}}>Ordo:</div>
                        <Select
                            showSearch
                            placeholder="Select a Ordo"
                            optionFilterProp="children"
                            defaultValue={"all"}
                            onChange={(value) => setOrdo(value)}
                            onSelect={(value, option) => {
                                setOrdoDescription(option.description);
                                setPageObj((oldState) => ({
                                    ...oldState,
                                    page: 1,
                                }));
                            }}
                            filterOption={filterOption}
                            options={ordoOptions}
                            style={{width: "100px", marginRight: "24px"}}
                        />
                        <div>{ordoDescription}</div>
                    </div>
                    {isAdministrator ? (
                        <div>
                            <Button type="primary" icon={<PlusOutlined/>} onClick={onNew}>
                                Add New Celebration
                            </Button>
                        </div>
                    ) : null}
                </div>
                <div className="d-flex" style={{width: "240px"}}>
                    <Search placeholder="Search" onSearch={handleSearch} enterButton/>
                </div>
            </div>
            <div>
                <Spin spinning={isFetching}>
                    <DashboardTable
                        bordered
                        columns={isAdministrator ? columns : columns.slice(0, -1)}
                        dataSource={celebrations.map((row, index) => ({
                            ...row,
                            index: index + 1 + (pageObj.page - 1) * pageObj.limit,
                        }))}
                        scroll={{
                            x: 1200,
                        }}
                        pagination={{
                            position: ["bottomRight"],
                            pageSize: pageObj.limit,
                            pageSizeOptions: ["15", "25", "50", "100"],
                            total: total,
                            current: pageObj.page,
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                setPageObj({page: current, limit: size});
                            },
                            onChange: (page, pageSize) => {
                                handleChange(page, pageSize);
                            },
                        }}
                        rowKey={(record) => record.id}
                    />
                </Spin>
            </div>
            <CelebrationModal
                isNew={isNew}
                editData={editData}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                ordoOptions={ordoOptions}
                rankOptions={rankOptions}
                refetch={refetch}
            />
        </div>
    );
};

export default Celebrations;
