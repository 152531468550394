import axios from "axios";
import Notification from "../components/Notification/Notification";
import {MESSAGE,} from "../constants/notification";
import {handleValidationError} from "../utils/errorHandler";

export const getOrdosByPagination = async (search, page, limit, setLoading) => {
    try {
        setLoading(true);
        const res = await axios.get(
            `/v2/ordos`, {params: {search, page, limit,}}
        );
        setLoading(false);

        return res.data;
    } catch (error) {
        setLoading(false);

        handleValidationError(error)
        throw error
    }
};

export const getOrdos = async () => {
    try {
        const res = await axios.get(`/v2/ordos`);
        return res.data.ordos || [];
    } catch (error) {
        handleValidationError(error)
        throw error
    }
};

export const createOrdo = async (data) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API}/v2/ordos`, data);

        if (res.status === 200) {
            Notification({type: "success", message: "New Ordo is created!"});
        }
    } catch (error) {
        handleValidationError(error)
        return error
    }
};

export const updateOrdo = async (id, data) => {
    try {
        const res = await axios.put(
            `${process.env.REACT_APP_API}/v2/ordos/${id}`,
            data
        );

        if (res.status === 200) {
            Notification({type: "success", message: "Ordo is updated!"});
        }
    } catch (error) {
        handleValidationError(error)
        return error
    }
};

export const deleteOrdo = async (id) => {
    try {
        const res = await axios.delete(
            `/v2/ordos/${id}`
        );

        if (res.status === 200) {
            Notification({type: "success", message: MESSAGE.ORDO_DELETED});
        }
    } catch (error) {
        handleValidationError(error)
    }
};
