import {useMutation, useQuery} from "@tanstack/react-query";
import {
    createCalendarEvent,
    deleteOverrides,
    executeOverrides,
    getOverridesByPagination,
    updateCalendarEvent
} from "../apis/overideApi";
import {MESSAGE, showSuccess} from "../constants/notification";
import {handleValidationError} from "../utils/errorHandler";

export const useFetchOverridesByPagination = (search, page, limit) => {
    return useQuery({
        queryKey: ['overrides', search, page, limit],
        queryFn: () => getOverridesByPagination(search, page, limit),
        keepPreviousData: false,
    });
};

export const useExecuteOverrides = (refetch) => {
    return useMutation({
        mutationFn: (ids) => executeOverrides(ids), onSuccess: () => {
            showSuccess(MESSAGE.OVERRIDE_EXECUTED)
            refetch();
        }, onError: (error) => {
            handleValidationError(error);
        }
    });
};

export const useDeleteOverrides = (refetch) => {
    return useMutation({
        mutationFn: (ids) => deleteOverrides(ids), onSuccess: () => {
            showSuccess(MESSAGE.OVERRIDE_DELETED);
            refetch();
        }, onError: (error) => {
            handleValidationError(error);
        }
    });
};

export const useCreateCalendarEvent = (refetch) => {
    const {mutate, isPending}=  useMutation({
        mutationFn: createCalendarEvent,
        onSuccess: () => {
            showSuccess(MESSAGE.OVERRIDE_CREATED)
            refetch?.()
        },
        onError: (error) => {
            handleValidationError(error);
        },
    });

    const handleCreateEvent = async (eventData) => {
        return await mutate(eventData);
    };
    return {
        handleCreateEvent,
        isPending,
    }
};

export const useUpdateCalendarEvent = (refetch) => {
    const {mutate, isPending, isError, isSuccess} = useMutation({
        mutationFn: updateCalendarEvent,
        onSuccess: () => {
            showSuccess(MESSAGE.OVERRIDE_SAVED)
            refetch?.()
        },
        onError: (error) => {
            handleValidationError(error);
        },
    });

    const handleUpdateEvent = async (id, updatedData) => {
        return await mutate({ id, data: updatedData });
    };

    return {
        handleUpdateEvent,
        isPending,
        isError,
        isSuccess,
    };
};
