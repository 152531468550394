import {useQuery} from "@tanstack/react-query";
import {getSeasons} from "../apis/seasonApi";

const useFetchSeasons = () => {
    const {
        data: seasonOptions = [],
        isFetching,
        refetch,
    } = useQuery({
        queryKey: ["seasons"],
        queryFn: async () => {
            const seasons = await getSeasons();
            return seasons?.map((item) => ({
                value: item.name,
                label: item.name,
            })) || [];
        },
    });

    return { seasonOptions, isFetching, refetch };
};

export default useFetchSeasons;
