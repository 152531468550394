import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow, MDBValidation, MDBValidationItem,} from "mdb-react-ui-kit";
import AuthCode from "react-auth-code-input";
import {confirm, forgotPassword, resened, resetPassword, signin, signup,} from "../../apis/authApi";
import verifyToken from "../../utils/verifyToken";
import BackImage from "../../assets/Surgeworks.png";
import {AUTH_STATUS} from "../../constants";

const buttonTextMap = {
  [AUTH_STATUS.SIGN_IN]: "Sign In",
  [AUTH_STATUS.SIGN_UP]: "Sign Up",
  [AUTH_STATUS.CONFIRM]: "Verify",
  [AUTH_STATUS.RESET_PASSWORD_1]: "Reset",
  [AUTH_STATUS.RESET_PASSWORD_2]: "Reset",
};

const Auth = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(AUTH_STATUS.SIGN_IN);
  const [formData, setFormData] = useState({ email: "", password: "", code: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const verify = async () => {
      if (await verifyToken()) navigate("/dashboard");
    };
    verify();
  }, [navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleAuthCodeChange = (confirmCode) => {
    setFormData((prev) => ({ ...prev, code: confirmCode }));
  };

  const toggleAuthStatus = () => {
    setStatus(status === AUTH_STATUS.SIGN_IN ? AUTH_STATUS.SIGN_UP : AUTH_STATUS.SIGN_IN);
    setFormData({ email: "", password: "", code: "" });
  };

  const handleSubmit = async () => {
    const { email, password, code } = formData;
    if (!email || (!password && status !== AUTH_STATUS.CONFIRM)) return;

    setLoading(true);
    try {
      let response;
      switch (status) {
        case AUTH_STATUS.SIGN_IN:
          response = await signin(email, password, setLoading);
          if (response === "User not confirmed") {
            setStatus(AUTH_STATUS.CONFIRM);
            await resened(email, setLoading);
          } else if (response?.id_token) {
            localStorage.setItem("idToken", response.id_token);
            localStorage.setItem("accessToken", response.access_token);
            localStorage.setItem("refreshToken", response.refresh_token);
            navigate("/dashboard");
          }
          break;
        case AUTH_STATUS.SIGN_UP:
          response = await signup(email, password, setLoading);
          if (response === "success") setStatus(AUTH_STATUS.CONFIRM);
          break;
        case AUTH_STATUS.CONFIRM:
          await confirm(email, code, setLoading);
          setStatus(AUTH_STATUS.SIGN_IN);
          break;
        case AUTH_STATUS.RESET_PASSWORD_1:
          if (await forgotPassword(email) === "success") setStatus(AUTH_STATUS.RESET_PASSWORD_2);
          else setStatus(AUTH_STATUS.SIGN_IN);
          break;
        case AUTH_STATUS.RESET_PASSWORD_2:
          await resetPassword(code, email, password);
          setStatus(AUTH_STATUS.SIGN_IN);
          break;
        default:
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
      <MDBValidation style={{ backgroundColor: "#f5f5f5" }}>
        <MDBContainer className="gradient-form d-flex align-items-center justify-content-center">
          <MDBRow className="bg-white" style={{ height: "80%", minHeight: "660px" }}>
            <MDBCol className="p-0 col-md-6 col-12">
              <div className="d-flex flex-column m-5">
                <div className="text-center">
                  <img src={BackImage} style={{ width: "185px" }} alt="logo" />
                  <h3 className="mt-1 mb-5 pb-1">We are Surgeworks</h3>
                </div>

                {status === AUTH_STATUS.CONFIRM || status === AUTH_STATUS.RESET_PASSWORD_2 ? (
                    <AuthCode
                        onChange={handleAuthCodeChange}
                        containerClassName="code-container"
                        inputClassName="code-item"
                    />
                ) : (
                    <>
                      <MDBValidationItem feedback="Please input the email." invalid>
                        <MDBInput
                            value={formData.email}
                            wrapperClass="mb-5"
                            label="Email"
                            id="email"
                            onChange={handleChange}
                            required
                        />
                      </MDBValidationItem>
                      {status !== AUTH_STATUS.RESET_PASSWORD_1 && (
                          <MDBValidationItem feedback="Please input the password." invalid>
                            <MDBInput
                                value={formData.password}
                                wrapperClass="mb-4"
                                label={status === AUTH_STATUS.RESET_PASSWORD_2 ? "New Password" : "Password"}
                                id="password"
                                type="password"
                                onChange={handleChange}
                                required
                            />
                          </MDBValidationItem>
                      )}
                    </>
                )}

                <div className="text-center pt-1 mb-5 pb-1">
                  <MDBBtn className={`mb-4 w-100 gradient-custom-2 ${loading ? "disabled" : ""}`} onClick={!loading ? handleSubmit : undefined}>
                    {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : buttonTextMap[status]}
                  </MDBBtn>
                  {status !== AUTH_STATUS.RESET_PASSWORD_1 && <div className="mb-4" onClick={toggleAuthStatus}>{status === AUTH_STATUS.SIGN_IN ? "Go to Sign Up" : "Go to Sign In"}</div>}
                  {status === AUTH_STATUS.SIGN_IN && <div onClick={() => setStatus(AUTH_STATUS.RESET_PASSWORD_1)}>Reset Password</div>}
                </div>
              </div>
            </MDBCol>
            <MDBCol className="p-0 col-md-6 col-12 gradient-custom-2 d-flex flex-column justify-content-center" style={{ height: "100%" }}>
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <h3 className="mb-4">Meet our Liturgical Calendar API</h3>
                <p className="small mb-0">Surgeworks specializes in rapid application development with iOS SDK, Android SDK, Ruby on Rails, PHP, Magento, and WordPress.</p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBValidation>
  );
};

export default Auth;
