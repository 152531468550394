import {useQuery} from "@tanstack/react-query";
import {getRanks} from "../apis/rankApi";

const useFetchRanks = () => {
    const {
        data: rankOptions = [],
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ["ranks"],
        queryFn: async () => {
            const ranks = await getRanks();
            return ranks?.map((item) => ({
                value: item.name,
                label: item.name,
            })) || [];
        },
    });

    return { rankOptions, isLoading, refetch };
};

export default useFetchRanks;
