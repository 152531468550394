import {BrowserRouter, Route, Routes} from "react-router-dom";

import MainLayout from "./components/Layout/MainLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import Auth from "./pages/auth/Auth";

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Auth/>}/>
                <Route path="/" element={<MainLayout/>}>
                    <Route path="dashboard/:currentTab?" element={<Dashboard/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
