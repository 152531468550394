import {useQuery} from "@tanstack/react-query";
import {getOrdos} from "../apis/ordoApi";

const useFetchOrdos = () => {
    const {
        data: ordoOptions = [],
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ["ordos"],
        queryFn: async () => {
            const ordos = await getOrdos();
            return ordos?.map((item) => ({
                value: item.id,
                label: item.id,
                description: item.description,
            })) || [];
        },
    });

    return { ordoOptions, isLoading, refetch };
};

export default useFetchOrdos;
