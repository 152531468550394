import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {createCelebration, deleteCelebration, getCelebrations, updateCelebration} from "../apis/celebrationApi";
import {MESSAGE, showSuccess} from "../constants/notification";
import {handleValidationError} from "../utils/errorHandler";
import {useMemo} from "react";

export const useCelebrations = (params) => {
    const memoizedParams = useMemo(() => params, [JSON.stringify(params)]);

    return useQuery({
        queryKey: ['celebrations', memoizedParams],
        queryFn: () => getCelebrations(params),
        enabled: !!params?.ordo
    });
};

export const useCreateCelebration = (callback) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: createCelebration, onSuccess: () => {
            queryClient.invalidateQueries(['celebrations']);
            showSuccess(MESSAGE.CELEBRATION_CREATED)
            callback?.()
        }, onError: (error) => {
            handleValidationError(error);
        }
    })

    const handleCreateCelebration = async (data) => {
        await mutation.mutate(data);
    };

    return {
        handleCreateCelebration,
        isPending: mutation.isPending,
        isError: mutation.isError,
        isSuccess: mutation.isSuccess,
    };
};

export const useUpdateCelebration = (callback) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({id, data}) => updateCelebration(id, data), onSuccess: () => {
            showSuccess(MESSAGE.CELEBRATION_UPDATED)
            queryClient.invalidateQueries(['celebrations']);
            callback?.()
        }, onError: (error) => {
            handleValidationError(error);
        }
    });

    const handleUpdateCelebration = async (id, data) => {
        await mutation.mutate({id, data});
    };

    return {
        handleUpdateCelebration,
        isPending: mutation.isPending,
        isError: mutation.isError,
        isSuccess: mutation.isSuccess,
    };
};

export const useDeleteCelebration = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteCelebration, onSuccess: () => {
            showSuccess(MESSAGE.CELEBRATION_DELETED)
            queryClient.invalidateQueries(['celebrations']);
        }, onError: (error) => {
            handleValidationError(error);
        }
    });

    const handleDeleteCelebration = (id) => {
        mutation.mutate(id);
    };

    return {
        handleDeleteCelebration, isError: mutation.isError, isSuccess: mutation.isSuccess,
    };
};
