import React, {useMemo, useState} from "react";
import {Button, Input, Popconfirm, Spin, Tag, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, PlayCircleOutlined, PlusOutlined} from "@ant-design/icons";
import DashboardTable from "../../../components/Table/DashboardTable";
import {useAuth} from "../../../context/AuthProvider";
import CalendarModal from "../calendar/CalendarModal";
import useManageEvent from "../../../hooks/useManageEvent";
import useFetchOrdos from "../../../hooks/useFetchOrdos";
import useFetchSeasons from "../../../hooks/useFetchSeasons";
import useFetchRanks from "../../../hooks/useFetchRank";
import {useDeleteOverrides, useExecuteOverrides, useFetchOverridesByPagination} from "../../../hooks/useOverrides";

const {Search} = Input;

const Overrides = () => {
    const {isAdministrator} = useAuth()
    const [search, setSearch] = useState("");
    const [pageObj, setPageObj] = useState({page: 1, limit: "15"});
    const [selectedRowKeys, setSelectedKeys] = useState([]);
    const {ordoOptions} = useFetchOrdos()
    const {seasonOptions} = useFetchSeasons()
    const {rankOptions} = useFetchRanks()
    const {
        isNew, editData, isModalOpen, setIsModalOpen, onNew, onEdit,
    } = useManageEvent()

    const {data, isFetching, refetch} = useFetchOverridesByPagination(search, pageObj.page - 1, pageObj.limit,);
    const {mutate: executeOverrides, isPending: isPendingExecute} = useExecuteOverrides(refetch);
    const {mutate: deleteOverrides, isPending: isPendingDelete} = useDeleteOverrides(refetch);
    const total = useMemo(() => data?.total, [data]);
    const events = useMemo(() => data?.events, [data]);

    const columns = [{
        title: "Date",
        key: "day",
        dataIndex: "day",
        width: "8%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Ordo",
        key: "ordo",
        dataIndex: "ordo",
        width: "3%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Title", key: "title", dataIndex: "title", align: "center",
    }, {
        title: "Precedence",
        key: "precedence_id",
        dataIndex: "precedence_id",
        width: "6%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Color",
        key: "color",
        dataIndex: "color",
        width: "5%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Rank",
        key: "rank_name",
        dataIndex: "rank_name",
        width: "7%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Season",
        key: "season_name",
        dataIndex: "season_name",
        width: "7%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Week in Season",
        key: "week_in_season",
        dataIndex: "week_in_season",
        width: "7%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Week in Psalter",
        key: "week_in_psalter",
        dataIndex: "week_in_psalter",
        width: "7%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Commons",
        key: "commons",
        dataIndex: "commons",
        width: "5%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Lable/Slug",
        key: "slug",
        dataIndex: "slug",
        width: "9%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Dominical",
        key: "dominical_letter",
        dataIndex: "dominical_letter",
        width: "5%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Year Cycle",
        key: "year_cycle_letter",
        dataIndex: "year_cycle_letter",
        width: "5%",
        align: "center",
        render: (text) => <center>{text}</center>,
    }, {
        title: "Action", key: "Action", dataIndex: "action", width: "5%", align: "center", render: (text) => {
            switch (text) {
                case "create":
                    return (<Tag color="#108ee9" key={text}>
                        {text.toUpperCase()}
                    </Tag>);
                case "update":
                    return (<Tag color="#87d068" key={text}>
                        {text.toUpperCase()}
                    </Tag>);
                case "delete":
                    return (<Tag color="#f00" key={text}>
                        {text.toUpperCase()}
                    </Tag>);
            }
        },
    }, {
        title: "", key: "", width: "5%", align: "center", render: (_, record) => (<center>
            <Button
                type="primary"
                icon={<EditOutlined style={{width: "12px", height: "12px"}}/>}
                style={{marginRight: "4px", width: "20px", height: "20px"}}
                onClick={() => onEdit(record)}
            />
            <Popconfirm
                title="Delete Overide"
                description="Are you sure to delete this overide?"
                onConfirm={() => onDeleteRow(record)}
                onCancel={() => {
                }}
                okText="Yes"
                cancelText="No"
            >
                <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined style={{width: "12px", height: "12px"}}/>}
                    style={{marginRight: "4px", width: "20px", height: "20px"}}
                />
            </Popconfirm>
        </center>),
    },];

    const onExecute = async () => {
        if (selectedRowKeys.length > 0) {
            await executeOverrides(selectedRowKeys);
            setSelectedKeys([]);
        }
    };

    const onDelete = async () => {
        if (selectedRowKeys.length > 0) {
            await deleteOverrides(selectedRowKeys);
            setSelectedKeys([]);
        }
    };
    const onDeleteRow = async (record) => {
        await deleteOverrides([record?.id]);
    };

    const onSelectChange = (newKeys) => {
        setSelectedKeys(newKeys);
    };

    const handleChange = async (page, pageSize) => {
        setPageObj((oldState) => ({
            ...oldState, page: page, limit: pageSize,
        }));
        refetch()
    };

    const handleSearch = async (value) => {
        setSearch(value);
        setPageObj((oldState) => ({
            ...oldState, page: 1,
        }));
    };

    return (<div className="p-4">
        <div className="mb-4">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <Search placeholder="Search" onSearch={handleSearch} enterButton/>
                </div>
                {isAdministrator ? (<div className="d-flex gap-1">
                    <div>
                        <Button type="primary" icon={<PlusOutlined/>} onClick={onNew}>
                            Add New Event
                        </Button>
                    </div>
                    <Tooltip
                        title={selectedRowKeys?.length === 0 ? 'Please select at least one row before executing!' : ''}>
                        <Button
                            disabled={selectedRowKeys?.length === 0}
                            type="primary"
                            icon={<PlayCircleOutlined/>}
                            onClick={onExecute}
                            loading={isPendingExecute}
                        >
                            Execute
                        </Button>
                    </Tooltip>

                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined/>}
                        onClick={onDelete}
                        loading={isPendingDelete}
                    >
                        Delete
                    </Button>
                </div>) : null}
            </div>
        </div>
        <div>
            <Spin spinning={isFetching}>
                <DashboardTable
                    bordered
                    columns={columns}
                    dataSource={events?.map((row, index) => ({
                        ...row, index: index + 1 + (pageObj.page - 1) * pageObj.limit,
                    }))}
                    scroll={{
                        x: 1200,
                    }}
                    pagination={{
                        position: ["bottomRight"],
                        pageSize: pageObj.limit,
                        pageSizeOptions: ["15", "25", "50", "100"],
                        total,
                        current: pageObj.page,
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            setPageObj({page: current, limit: size});
                        },
                        onChange: (page, pageSize) => {
                            handleChange(page, pageSize);
                        },
                    }}
                    rowKey={(record) => record.id}
                    rowSelection={{
                        selectedRowKeys, onChange: onSelectChange, selections: [{
                            key: "create", text: "Select All Created Events", onSelect: (changeableRowKeys) => {
                                let newKeys = [];
                                newKeys = changeableRowKeys.filter((_, index) => {
                                    if (data[index].action == "create") {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                });
                                setSelectedKeys(newKeys);
                            },
                        }, {
                            key: "update", text: "Select All Updated Events", onSelect: (changeableRowKeys) => {
                                let newKeys = [];
                                newKeys = changeableRowKeys.filter((_, index) => {
                                    if (data[index].action == "update") {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                });
                                setSelectedKeys(newKeys);
                            },
                        }, {
                            key: "delete", text: "Select All Deleted Events", onSelect: (changeableRowKeys) => {
                                let newKeys = [];
                                newKeys = changeableRowKeys.filter((_, index) => {
                                    if (data[index].action == "delete") {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                });
                                setSelectedKeys(newKeys);
                            },
                        }, {
                            key: "today", text: "Today Events", onSelect: (changeableRowKeys) => {
                                let newKeys = [];
                                let now = new Date();
                                now.setHours(0, 0, 0, 0);
                                newKeys = changeableRowKeys.filter((_, index) => {
                                    if (new Date(data[index].created_at).getTime() > now.getTime()) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                });
                                setSelectedKeys(newKeys);
                            },
                        },],
                    }}
                />
            </Spin>
        </div>
        {isModalOpen && <CalendarModal
            isNew={isNew}
            editData={editData}
            canDelete={!isNew}
            canCreate={isNew}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            ordoOptions={ordoOptions}
            rankOptions={rankOptions}
            seasonOptions={seasonOptions}
            callback={()=> {
                refetch()
            }}
        />}

    </div>);
};

export default Overrides;
