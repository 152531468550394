import {useState} from 'react';

const useManageEvent = () => {
    const [isNew, setIsNew] = useState(false);
    const [editData, setEditData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onNew = () => {
        setIsNew(true);
        setIsModalOpen(true);
        setEditData({})
    };

    const onEdit = (record) => {
        setIsNew(false);
        setEditData(record);
        setIsModalOpen(true);
    };



    return {
        isNew,
        setIsNew,
        editData,
        setEditData,
        isModalOpen,
        setIsModalOpen,
        onNew,
        onEdit,
    };
};

export default useManageEvent;
