import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {jwtDecode} from "jwt-decode";
import verifyToken from "../utils/verifyToken";
import setupAxiosInterceptors from "../apis/setupAxios";

const AuthContext = createContext({ isAdministrator: false, isAuthenticated: false , userInfo: null });
setupAxiosInterceptors(()=> {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href='/'
})
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(localStorage.getItem("idToken"))
    }, [localStorage.getItem("idToken")]);

    useEffect(() => {
        const checkAuth = async () => {
            const result = await verifyToken();
            setIsAuthenticated(result || false);
        };
        checkAuth();

    }, []);

    const { isAdministrator, userInfo } = useMemo(() => {
        try {
            if (!token) return { isAdministrator: false, userInfo: null };

            const decoded = jwtDecode(token);
            const groups = decoded["cognito:groups"] || [];
            const isAdmin = Boolean(groups?.includes("administrators"));
            return { isAdministrator: isAdmin, userInfo: decoded };
        } catch (error) {
            console.error("Error decoding token!", error);
            return { isAdministrator: false, userInfo: null };
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, isAdministrator, userInfo }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
